<template>
    <v-container fluid grid-list-lg>
        <v-row class="my-2">
            <v-col cols="12">
                <h1 class="text-h4 mb-6">Denne siden finnes ikke</h1>
                <div class="text-body-1">Forsøk å gå til forsiden, eller bruk menyen for å finne siden du leter etter.</div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>
